import * as React from 'react';
import { CompactTable, Column as TableLibraryColumn } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/mantine';
import { Collapse } from '@mantine/core';

// ------------------
// Define Custom Types
// ------------------

/**
 * Define the types of data you expect in each column.
 */
type ColumnType = 'string' | 'number' | 'date' | 'boolean' | 'custom';

/**
 * Define your custom TableColumn interface.
 * This interface does NOT extend the library's Column<any> to avoid type conflicts.
 */
interface TableColumn {
  id: string; // Unique identifier corresponding to data keys
  label: string; // Display label for the column header
  type?: ColumnType; // (Optional) Type of data for formatting purposes
  renderCell?: (item: any) => React.ReactNode; // (Optional) Custom cell rendering
}

// ------------------
// Define Component Props
// ------------------

interface TableComponentProps {
  data: Array<{ [key: string]: any }>;
  columns: TableColumn[]; // Use the custom TableColumn interface
  idColumn: string; // The key in data that uniquely identifies each row
  columnToShow?: number; // Optional: Number of columns to display in the table
}

// ------------------
// TableComponent
// ------------------

export const TableComponent: React.FC<TableComponentProps> = ({
  data,
  columns,
  idColumn,
  columnToShow,
}) => {
  console.log(columnToShow,"columnToShow")
  // Prepare data nodes for the table library
  const nodes = { nodes: data };

  // Define themes
  const mantineTheme = getTheme(DEFAULT_OPTIONS);
  const customTheme = {
    Table: `
      .animate {
        grid-column: 1 / -1;
        display: flex;
      }

      .animate > div {
        flex: 1;
        display: flex;
      }
          .clickable-row {
      cursor: pointer;
    }

    .clickable-row:hover {
      background-color: #f5f5f5; /* Light gray on hover */
    }
    `,
  };
  const theme = useTheme([mantineTheme, customTheme]);

  // State to manage expanded rows
  const [expandedIds, setExpandedIds] = React.useState<string[]>([]);

  // Handle row expansion toggle
  const handleExpand = (item: any) => {
    const itemId = item[idColumn];
    setExpandedIds((prevIds) =>
      prevIds.includes(itemId)
        ? prevIds.filter((id) => id !== itemId)
        : [...prevIds, itemId]
    );
  };

  // Optionally limit the number of columns to show
  const displayedColumns = columnToShow
    ? columns.slice(0, columnToShow)
    : columns;

    const rowsColumns = (columnToShow ? columns.slice(columnToShow, columns.length): columns).map((a:any)=>a.id)
    ;

  // Map custom TableColumn to library's Column<any>, ensuring renderCell is provided
  const libraryColumns: TableLibraryColumn<any>[] = displayedColumns.map((col) => ({
    label: col.label,
    renderCell: col.renderCell
      ? col.renderCell
      : (item: any) => {
          const value = item[col.id];
          let displayValue: React.ReactNode;

          // Default formatting based on column type
          switch (col.type) {
            case 'date':
              displayValue = value
                ? new Date(value).toLocaleDateString('en-US')
                : 'N/A';
              break;
            case 'boolean':
              displayValue = value ? 'Yes' : 'No';
              break;
            case 'number':
              displayValue =
                value !== null && value !== undefined
                  ? value.toLocaleString()
                  : 'N/A';
              break;
            default:
              displayValue =
                value !== null && value !== undefined ? value : 'N/A';
          }

          return <span>{displayValue}</span>;
        },
  }));

  // Define row properties, including click handler for expansion
  const rowProps = {
    onClick: handleExpand,
    className: 'clickable-row'
  };
  // Define how to render content after each row (expanded content)
  const rowOptions = {
    renderAfterRow: (item: any) => {
      console.log(item,"item")
      return (

        <Collapse className="animate" in={expandedIds.includes(item[idColumn])} >
  <tr style={{ flex: '1', display: 'flex' }}>
    <td style={{ flex: '1' }} colSpan={displayedColumns.length} key={item.id}>
      <ul style={{ margin: '0', padding: '0', listStyleType: 'none' }}>
        {columns.map((column) => {
          const value = item[column.id];
          let displayValue: React.ReactNode;

          // Dynamically format the value based on the column type
          switch (column.type) {
            case 'date':
              displayValue = value
                ? new Date(value).toLocaleDateString('en-US')
                : 'N/A';
              break;
            case 'boolean':
              displayValue = value ? 'Yes' : 'No';
              break;
            case 'number':
              displayValue =
                value !== null && value !== undefined
                  ? value.toLocaleString()
                  : 'N/A';
              break;
            case 'custom':
              // If a custom render function is provided, use it
              displayValue = column.renderCell
                ? column.renderCell(item)
                : value;
              break;
            default:
              displayValue =
                value !== null && value !== undefined ? value : 'N/A';
          }

          // Make sure we only return the columns specified by rowsColumns
          if (!rowsColumns.includes(column.id)) return null;

          return (
            <li key={`${item[idColumn]}-${column.id}`}>  {/* Fix: Add a unique key */}
              <strong>{column.label}:</strong> {displayValue}
            </li>
          );
        })}
      </ul>
    </td>
  </tr>
</Collapse>
      )
    },
  };

  return (
    <CompactTable
      columns={libraryColumns}
      rowProps={rowProps}
      rowOptions={rowOptions}
      data={nodes}
      theme={theme}
    />
  );
};
