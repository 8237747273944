import styled from "styled-components";
import { Footer } from "./Footer";
import { Header } from "./Header";

interface LayoutProps {
    children: React.ReactNode;
  }


export const BaseLayout = ({children}:LayoutProps)=>{
    return (
        <>
        <Header/>
        <Body>
            {children}
        </Body>
        <Footer/>
        </>
    )

}
const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* align-items: center; // Center content vertically */
  width: 100%;
  min-height: 65vh; // Minimum height to cover the full viewport height
  background-size: cover;
  background-position: center; // Ensure the background is centered

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
