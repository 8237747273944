import { useEffect, useState } from "react";
import { Col, Container, Img, Title, WrappedRow, Text, Btn, Row } from "../style";
import { Backgrounds, Icons, Images } from "../constants/images";
import styled from "styled-components";
import { Colors } from "../theme/colors";
import { FormComponent } from "../components/generic/Form";
import { FieldType, TextFieldType } from "../components/fields";
import Joi from "joi";
import { BaseLayout } from "../components/layout/BaseLayout";
import { useFormHandling } from "../hooks/FormHandle";
import { RequestConfigs } from "../api";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeviceType } from "../context/DeviceContext";
import { PercentageProgressBar } from "../components/generic/ProgressBar";
import DynamicTable from "../components/generic/Table";
import { Products } from "../components/home/products";
import { useCustomer } from "../context/CustomerContext";
import { RoyaltyStats } from "../components/royalty/royaltyStats";
import { RoyaltyPoints } from "../components/royalty/royaltyPoints";


export const RoyaltyPage = () => {

    const location = useLocation()
    const { customer } = useCustomer()
    const { isMobile } = useDeviceType()

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);


    console.log(customer,"customer")






    return (
        <>
            <BaseLayout>

                <Section $isMobile={isMobile}>
                    <Container>
                        <Row>
                            <Col align="start">
                                <Col justify="center" align="start" $maxWidth="600px" gap="10px">

                                    <Title $isMobile={isMobile} $fontSize={54} $lineHeight={64} color={Colors.blackText}>
                                        Your Royalty Points
                                    </Title>
                                    <Col $maxWidth="450px" margin="0px 0px 20px 0px">
                                        <Text weight={600} color={Colors.blackText}>
                                            Earn <b>35%</b> of your order value in royalty points with every purchase. Once you accumulate 100 points, you'll receive a <b>$100</b> credit for your next order.
                                        </Text>
                                    </Col>

                                    {
                                        !customer &&
                                            
                                            <Row justify="flex-start">
                                            <Col align="start">
                                                <Btn width="240px" to={"/login"}>Login</Btn>
                                                <Col align="start">
                                                    <Text weight={500} color={Colors.whiteBackground}>Login to check your points </Text>
                                                </Col>
                                            </Col>
                                            </Row>
                                    }

                                            {
                                                customer &&
                                                <>
                                                    <RoyaltyPoints />
                                                    <Btn width="240px" to={"#shop"}>Shop Now</Btn>
                                                </>
                                            }

                                        </Col>

                            </Col>
                        </Row>

                    </Container>

                </Section>
                {
                    customer &&
                    <RoyaltyStats />
                }

                <Products />


            </BaseLayout>

        </>

    )

};

const FormBox = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 350px;
    gap: 15px;
    padding: 10px;
  overflow: hidden; 
    /* Adding the semi-circle */
    &::before {
        content: '';
        position: absolute;
        top: -30px; /* Adjust the position as needed */
        right: -30px; /* Adjust the position as needed */
        width: 80px; /* Adjust the size as needed */
        height: 80px; /* Adjust the size as needed */
        background-color: ${Colors.primary}; /* The color of the semi-circle */
        border-radius: 50%;
    }
`;
const Section = styled.div<{ $isMobile?: boolean }>`
  position: relative;
  width: 100%;
  height: 668px;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:${props=>props.$isMobile?'rgba(255, 255, 255, 0.6)':'none'};
    background-image:${props=>props.$isMobile?`url(${Backgrounds.Coins})`:`url(${Backgrounds.LoyaltyBg})`} ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay; /* This blends the color and image */
    
    z-index: -1; /* Ensures background is behind the content */
  }
`;